<template>
  <div class="w-full flex flex-row">
    <div class="w-full max-w-screen-xl flex flex-col lg:flex-col">
      <ProfileGeneral />
      <ProfilePassword />
    </div>
  </div>
</template>

<script>
import ProfileGeneral from "@/components/Customer/ProfileGeneral";
import ProfilePassword from "@/components/Customer/ProfilePassword";
export default {
  components: {
    ProfileGeneral,
    ProfilePassword,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style>
</style>