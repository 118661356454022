<template>
  <div>
    <Wrapper class="max-w-2xl">
      <ProfileForm />  
    </Wrapper>
  </div>
</template>

<script>
import ProfileForm from '@/components/Customer/ProfileForm'
import Wrapper from '@/components/UI/Wrapper'
export default {
  components: {
    Wrapper,
    ProfileForm
  }
}
</script>

<style>

</style>