<template>
  <div class="relative">
    <div class="w-full mb-4">
      <h2 class="mb-4 text-blue-dark text-2xl md:text-3xl text-left flex gap-4">
        <b>Mi Perfil</b>

        <a-tooltip
          v-if="!loading && noEdit"
          placement="right"
          title="Presione el botón para habilitar la edición"
        >
          <a-button
            id="edit"
            type="primary"
            ghost
            shape="circle"
            @click="startEdit"
            class="w-10 xl:w-10 text-white p-0"
          >
            <a-icon
              type="edit"
              :style="{ fontSize: '17px', color: 'blue' }"
              class="font-normal"
            />
          </a-button>
          <label
            for="edit"
            class="cursor-pointer text-sm font-thin mx-2 relative top-3"
            >Editar</label
          >
        </a-tooltip>
        <span v-else class="text-sm mt-3 text-black"> Edición habilitada</span>
      </h2>
      
      <form action="" ref="userForm">
        <div v-if="user && user.person.person_type !== 2">
          <h3 class="text-blue text-left"><b>Datos de contacto:</b></h3>
          <BaseInput
            ref="names"
            type="text"
            label="Nombre completo"
            placeholder="Julio Roman"
            required="true"
            :maxlength="25"
            v-model="form.first_name"
            :disabled="noEdit"
          />
          <BaseInput
            ref="last_name"
            type="text"
            label="Apellidos"
            v-model="form.last_name"
            :disabled="noEdit"
            placeholder="Henriquez"
            required="true"
            :maxlength="24"
          />
          <BaseInput
            type="text"
            label="Tipo de identificación"
            v-model="user.person.identification_type.name"
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Número identificación"
            v-model="user.person.identification_number"
            :disabled="true"
          />

          <BaseInput
            type="text"
            label="Fecha de nacimiento"
            v-model="user.person.birth_date"
            :disabled="noEdit"
            v-if="user.person.birth_date && noEdit"
          />
          <BaseInput
            v-if="user.phone && noEdit"
            type="text"
            label="Número de celular"
            v-model="form.phone"
            :disabled="noEdit"
          />

          <BaseDate
            v-if="!noEdit"
            ref="birthdate"
            id="birthdate"
            label="Fecha de nacimiento"
            placeholder=""
            v-model="form.birth_date"
            :value="form.birth_date"
            :disabledDate="disabledDate"
            :defaultDate="form.birth_date"
            required="true"
          />
          <BaseNumber
            v-if="!noEdit"
            ref="cellphone"
            label="Número de celular"
            placeholder="123412341234"
            v-model="form.phone"
            required="true"
          />
          <BaseSelect
            ref="department"
            id="department"
            label="Seleccione un departamento"
            :selectOptions="formOptions.optionsDepartments"
            v-model="form.departamento_cod"
            :disabled="noEdit"
            @input="resetMunicipality()"
            v-if="user.departamento_cod || !noEdit"
          />
          <BaseSelect
            ref="city"
            id="city"
            label="Seleccione un municipio"
            :selectOptions="optionsMunicipalities"
            v-model="form.municipio_cod"
            :disabled="noEdit"
            v-if="user.municipio_cod || !noEdit"
          />
          <BaseInput
            ref="address"
            id="address"
            label="Dirección"
            v-model="form.address"
            :maxlength="50"
            placeholder="Cra. 1 ## 1A-11"
            type="text"
            v-if="user.address || !noEdit"
            :disabled="noEdit"
          />
          <BaseInput
            ref="email"
            type="text"
            label="Correo electrónico"
            v-model="form.email"
            :disabled="noEdit"
            placeholder="ejemplo@ejemplo.com"
            required="true"
            autocomplete="username"
            :maxlength="50"
          />
          <BaseInput
            v-if="!noEdit"
            ref="email_confirmation"
            type="text"
            label="Confirmar correo electrónico"
            v-model="form.emailConfirmation"
            placeholder="ejemplo@ejemplo.com"
            required="true"
            autocomplete="username"
            :maxlength="50"
          />
        </div>

        <!-- #region Persona júridica -->
        <div v-if="user && user.person.person_type == 2">
          <h2 class="my-5 text-lg text-left text-blue">
            <b>Datos de contacto</b>
          </h2>
          <BaseInput
            ref="names"
            type="text"
            label="Nombre completo"
            placeholder="Julio Roman"
            required="true"
            :maxlength="25"
            v-model="form.first_name"
            :disabled="noEdit"
          />
          <BaseInput
            ref="last_name"
            type="text"
            label="Apellidos"
            v-model="form.last_name"
            :disabled="noEdit"
            placeholder="Henriquez"
            required="true"
            :maxlength="24"
          />
          <BaseInput
            type="text"
            label="Tipo de identificación"
            v-model="user.person.identification_type.name"
            :disabled="true"
          />
          <BaseInput
            ref="nit"
            type="text"
            id="nit"
            label="NIT"
            v-model="user.person.identification_number"
            :disabled="true"
            v-if="user.person.identification_number"
          />
          <BaseInput
            ref="company_name"
            type="text"
            id="razonSocial"
            label="Razón social"
            v-model="form.business_name"
            :disabled="noEdit"
            required="true"
          />
          <BaseInput
            v-if="user.phone && noEdit"
            type="text"
            label="Número de celular"
            placeholder="123412341234"
            v-model="user.phone"
            :disabled="true"
          />
          <BaseNumber
            v-if="!noEdit"
            ref="cellphone"
            label="Número de celular"
            placeholder="123412341234"
            v-model="form.phone"
          />
          <BaseSelect
            ref="department"
            id="department"
            label="Seleccione un departamento"
            :selectOptions="formOptions.optionsDepartments"
            v-model="form.departamento_cod"
            :disabled="noEdit"
            @input="resetMunicipality()"
            v-if="user.departamento_cod || !noEdit"
          />
          <BaseSelect
            ref="city"
            id="city"
            label="Seleccione un municipio"
            :selectOptions="optionsMunicipalities"
            v-model="form.municipio_cod"
            :disabled="noEdit"
            v-if="user.municipio_cod || !noEdit"
          />
          <BaseInput
            ref="address"
            id="address"
            label="Dirección"
            v-model="form.address"
            :maxlength="50"
            placeholder="Cra. 1 ## 1A-11"
            type="text"
            v-if="user.address || !noEdit"
            :disabled="noEdit"
          />

          <BaseInput
            ref="email"
            type="text"
            label="Correo electrónico"
            v-model="form.email"
            :disabled="noEdit"
            placeholder="ejemplo@ejemplo.com"
            autocomplete="username"
            :maxlength="50"
          />
          <BaseInput
            v-if="!noEdit"
            ref="email_confirmation"
            type="text"
            label="Confirmar correo electrónico"
            v-model="form.emailConfirmation"
            placeholder="ejemplo@ejemplo.com"
            autocomplete="username"
            :maxlength="50"
          />
        </div>
        <!-- #endregion -->
        <div v-if="feedback">
          <p class="mt-2 mb-4 text-sm text-center text-red-500">
            {{ feedback }}
          </p>
        </div>
        <div
          v-if="!noEdit"
          class="grid grid-cols-2 mt-9 flex justify-end mx-auto gap-5 w-11/12"
        >
          <a-button
            type="danger"
            @click="cancelEdit"
            shape="round"
            ghost
            :block="true"
            :loading="loading"
          >
            <span class="mx-2">CANCELAR</span>
          </a-button>
          <a-button
            type="primary"
            @click="saveForm"
            shape="round"
            :block="true"
            :disabled="!changedForm"
            :loading="loading"
          >
            <span class="mx-2">GUARDAR</span>
          </a-button>
        </div>
      </form>
    </div>
    <div
      v-if="loading"
      class="spinner absolute w-full h-full top-0 flex items-center justify-center"
    >
      <a-spin class="">
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        business_name: "",
        birth_date: "",
        phone: "",
        deparmento_cod: "",
        municipio_cod: "",
        address: "",
        email: "",
        emailConfirmation: "",
        username: "",
      },
      optionsMunicipalities: [],
      loading: false,
      noEdit: true,
      feedback: "",
      oldForm: {},
      disabledDate: (current) => {
        // Can not select days after today
        return current && current > moment().endOf("day");
      },
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.loading = true;
      let { data, error } = await this.$api.getUser(this.user.id);

      if (error) {
        this.feedback = error
          ? error?.error
          : "Error desconocido, intente más tarde";
        this.$notification.error({
          message: "Notificación",
          description: "Error al actualizar el perfil del usuario",
        });
      }
      if (data) {
        this.form = {
          first_name: data.first_name,
          last_name: data.last_name,
          business_name: data.business_name ? data.business_name : "",
          birth_date: data.person.birth_date,
          phone: data.phone,
          address: data.address ? data.address : "",
          departamento_cod: data.departamento_cod ? data.departamento_cod : "",
          municipio_cod: data.municipio_cod ? data.municipio_cod : "",
          email: data.email,
          emailConfirmation: data.email,
          username: data.username,
        };
        this.oldForm = JSON.stringify(this.form);
      }
      this.loading = false;
    },
    startEdit() {
      this.noEdit = false;
    },
    cancelEdit() {
      this.noEdit = true;
      this.getUser();
    },
    resetMunicipality() {
      this.form.municipio_cod = "";
    },
    async saveForm() {
      // Validaciones.
      if (this.checkForm() && this.validateRequiredField()) {
        this.loading = true;
        let infoToUpdate = {
          ...this.form,
          username: this.form.email ? null : this.form.username,
          address: this.form.address === "" ? null : this.form.address,
        };
        let { data, error } = await this.$api.updateUserProfile(
          this.user.id,
          infoToUpdate
        );

        this.loading = false;

        if (error) {
          this.feedback = error
            ? error?.error
            : "Error desconocido, intente más tarde";
          this.$notification.error({
            message: "Notificación",
            description:
              "Error al actualizar el perfil del usuario:" + " " + error.error,
          });
        }
        // show message
        if (data) {
          this.$notification.success({
            message: "Notificación",
            description: "Actualización exitosa",
          });
          this.$notification.open({
            message: 'Notificación',
            description:
              'Tenga en cuenta que, en el caso de que su inconformidad se refiera a una solicitud de conciliación o a una demanda, los cambios en su dirección física o electrónica de notificación deben ser solicitados mediante escrito remitido en medio físico o digital aquí o remitidos a la dirección física de esta Superintendencia. De lo contrario las comunicaciones continuarán siendo remitidas a la dirección registrada inicialmente.',
            icon: <a-icon type="smile" style="color: #108ee9" />,
            duration: 30
          });

          this.form = data;
          this.noEdit = true;
          this.getUser();
          this.$store.dispatch("session/loadPersonalData");
        }
      }
    },
    checkForm() {
      let valid = false;
      let regex = /[^a-zñÑ\s]/i;
      let regexNIT = /[0-9]{7,}\-[0-9]{1}/;
      if (
        regex.test(this.form.first_name + "") ||
        regex.test(this.form.last_name + "")
      ) {
        this.feedback =
          "En los campos nombres y apellidos no se permiten caracteres especiales ni acentos";
        return valid;
      }

      // Validación para NIT
      if (
        this.form.identification_type == 3 &&
        !regexNIT.test(this.form.identification_number + "")
      ) {
        this.feedback = "Ingrese un NIT válido";
        return valid;
      }
      if ((this.form.identification_number + "").length > 15) {
        this.feedback =
          "La longitud máxima del número de identificación debe ser 15.";
        return valid;
      }
      if (this.form.phone) {
        if (
          !this.$validator.isNumeric(this.form.phone + "") ||
          this.form.phone.toString().length < 7
        ) {
          this.feedback = "Ingrese un número de celular válido.";
          return valid;
        }

        if (this.form.phone.toString().length > 15) {
          this.feedback = "El número de teléfono no puede ser mayor a 15.";
          return valid;
        }
      }
      if (this.form.birth_date) {
        this.form.birth_date =
          typeof this.form.birth_date == "object"
            ? this.form.birth_date.format("YYYY-MM-DD")
            : this.form.birth_date;
      } else {
        delete this.form.birth_date;
      }
      if (this.form.email && !this.$validator.isEmail(this.form.email)) {
        this.feedback = "Ingrese un correo válido";
        return valid;
      } else if (
        this.form.emailConfirmation &&
        !this.$validator.equals(this.form.email, this.form.emailConfirmation)
      ) {
        this.feedback = "El correo electrónico no coincide";
        return valid;
      }

      this.feedback = "";
      valid = true;
      return valid;
    },
    validateRequiredField() {
      let refs = this.$refs;

      let valid = false;
      this.feedback = "";
      if (Object.keys(this.form).length === 0) {
        this.feedback = "Verifique los campos obligatorios";
        return valid;
      }

      for (let ref in refs) {
        if (refs[ref] && refs[ref].required && refs[ref].value === "") {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }
      return true;
    },
  },
  computed: {
    user: function () {
      return this.$store.state.session.user;
    },
    formOptions: function () {
      return this.$store.state.form;
    },
    changedForm: function () {
      if (this.oldForm !== JSON.stringify(this.form)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;
    },
  },
};
</script>

<style></style>
